/*
 * @Description:
 * @Author: chenfeifei
 * @Version: 1.0.0
 * @Date: 2022-08-25 20:54:07
 * @LastEditTime: 2022-08-25 20:56:49
 */
export const DATE_TIME_FORMATE = 'YYYY-MM-DD HH:mm';
export const DATE_FORMATE = 'YYYY-MM-DD';
export const BASIC_STORAGE = 'smart-campus-web@';
export const MD5_KEY = 'api.smartcampus.com';
// export const CORP_ID = 'ww7ee0401cbd125783';
// jiangnan
// export const CORP_ID = 'ww8bd5370382a0cf2a';
// 2022-08-25
export const CORP_ID = 'wwb6019c01b4f5a4b7';
